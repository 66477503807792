import { viewpointActions } from '.';
import { propertyAssignmentStatus } from '../../components/enums';
import { apiService } from '../../services';
import { detailConstants, emailConstants, modelConstants, processConstants, projConstants } from '../constants';

export const syncActions = {
    syncProject,
    syncIssue
}

function syncProject(projId) {
    return dispatch => {
        apiService.getArrayData('project', [projId]).then(projects => {
            const project = projects[0];
            apiService.getArrayData('tag', project.tagIds).then(data => {
                project.tags = data;
            });

            if (project.workPhaseIds) {
                apiService.getArrayData('workPhase', project.workPhaseIds).then(workPhases => {
                    project.workPhases = workPhases;
                }).catch(err => {
                    console.log("Can't load the workphase in project.", err.message);
                });
            }

            dispatch(setCurrentProject(project));
            if (project.modelIds) {
                apiService.getArrayData('model', project.modelIds).then(models => {
                    dispatch(updateModels(models));
                })
            }

            if (project.processIds) {
                apiService.getArrayData('process', project.processIds).then(processes => {
                    dispatch(updateProcesses(processes));
                });
            }

            apiService.getDataByEndpoint(`inbox/project/${projId}`).then(res => {
                dispatch(registerEmails(res.data));
            });
        }).catch(err => {
            console.log(err.message);
        })
    }

    function setCurrentProject(project) {
        return {
            type: projConstants.PROJ_OPEN,
            payload: project
        }
    }

    function updateModels(models) {
        let previewDtos = models.map(m => {
            return m.changedFile;
        });

        return {
            type: modelConstants.MODEL_GETALL,
            payload: previewDtos
        };
    }

    function updateProcesses(processes) {
        const approved = processes.filter(p => p.status === propertyAssignmentStatus.Approved);
        return {
            type: processConstants.PROCESS_GETALL,
            payload: approved
        };
    }

    function registerEmails(emails) {
        return {
            type: emailConstants.GET_EMAIL,
            payload: emails
        };
    }
}

function syncIssue(id) {
    return dispatch => {
        apiService.getArrayData('issue', [id]).then(issues => {
            const issue = issues[0];
            if (issue.viewpointIds.length) {
                dispatch(viewpointActions.getViewpoint(issue.viewpointIds));
            }

            dispatch(updateIssue(issue));
        }).catch(err => {
            console.log(err);
        });
    }

    function updateIssue(issue) {
        return {
            type: detailConstants.DETAIL_SELECT,
            payload: issue
        }
    }
}