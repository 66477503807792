export class ProcessModel {
    constructor(processDto) {
        this.id = processDto.id;
        this.process = processDto;
        this.model = undefined;
        this.discipline = undefined;
        this.building = undefined;
        this.floor = undefined;
        this.user = undefined;
        this.imported = false;
        this.importedDate = undefined;
        this.description = undefined;
    }
}
