import { projConstants } from "../constants";

const initialState = {
  projects: [],
  selectedProject: {},
};

export function project(state = initialState, action) {
  switch (action.type) {
    case projConstants.PROJ_GETAll: {
      return {
        ...state,
        projects: action.payload,
      };
    }
    case projConstants.PROJ_OPEN: {
      return {
        ...state,
        selectedProject: action.payload,
      };
    }
    case projConstants.PROJ_CLEAR: {
      return initialState;
    }
    case projConstants.PROJ_RETURN: {
      return {
        ...state,
        selectedProject: {},
      };
    }
    case projConstants.PROJ_RESETISSUEFLAG: {
      return state;
    }

    default:
      return state;
  }
}
