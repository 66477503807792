import { apiService } from "../../services";
import { issueDescriptorConstants } from "../constants";

export const issueDescriptorActions = {
  getIssueDescriptors,
};

function getIssueDescriptors(projectId) {
  return (dispatch) => {
    apiService
      .getDataByEndpoint("issue-priority")
      .then((priorities) => {
        dispatch(setPriorities(priorities.data));
      })
      .catch((err) => {
        console.log(err.message);
      });

    apiService
      .getDataByEndpoint("issue-status")
      .then((statuses) => {
        dispatch(setStatuses(statuses.data));
      })
      .catch((err) => {
        console.log(err.message);
      });

    apiService
      .getDataByEndpoint("issue-type")
      .then((types) => {
        dispatch(setTypes(types.data));
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  function setPriorities(priorities) {
    return {
      type: issueDescriptorConstants.PRIORITY,
      payload: priorities,
    };
  }

  function setStatuses(statuses) {
    return {
      type: issueDescriptorConstants.STATUS,
      payload: statuses,
    };
  }

  function setTypes(types) {
    return {
      type: issueDescriptorConstants.TYPE,
      payload: types,
    };
  }
}
