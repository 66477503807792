import React from "react";
import { connect } from "react-redux";
import ModelPage from "./models";
import IssueList from "./issueList";
import IssueDetail from "./issueDetail";
import IssueFilter from "./issueFilter";
import IssueEdit from "./issueEdit";
import ExportPage from "./exportManager";
import EditProcess from "./editProcess";
import { projActions, syncActions, userActions } from "../redux/actions";
import { pageState } from "./enums";
import en from "../assets/en.json";
import de from "../assets/de.json";
import logoutImg from "../assets/logout.png";
import "../css/manager.css";

class IssueManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentState: pageState.IMPORTMODEL,
      tempState: pageState.ISSUELIST,
    };

    this.handleGotoProjects = this.handleGotoProjects.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleGotoModelPage = this.handleGotoModelPage.bind(this);
    this.handleGotoIssuePage = this.handleGotoIssuePage.bind(this);
    this.handleChangeIssuePage = this.handleChangeIssuePage.bind(this);
    this.handleGotoExportPage = this.handleGotoExportPage.bind(this);
  }

  componentDidMount() {
    if (!this.props.project.id) {
      this.props.history.push("/");
    } else {
      window.getPublishHistory(this.props.project.id);
      this.createInterval();
    }
  }

  componentWillUnmount() {
    window.savePublishHistory();
    this.disposeInterval();
  }

  createInterval() {
    this.timerId = setInterval(() => {
      this.props.syncProject(this.props.project.id);
    }, 60000);
  }

  disposeInterval() {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  }

  handleGotoProjects(e) {
    e.preventDefault();
    this.props.returnProjects(this.props.history);
  }

  handleLogout(e) {
    e.preventDefault();
    this.props.logout(this.props.history);
  }

  handleGotoModelPage(e) {
    e.preventDefault();
    this.registerTempState();
    this.setState({ currentState: pageState.IMPORTMODEL });
  }

  handleGotoIssuePage(e) {
    e.preventDefault();
    const temp = this.state.tempState;
    this.setState({ currentState: temp });
  }

  handleChangeIssuePage(page) {
    this.setState({ currentState: page });
  }

  handleGotoExportPage(e) {
    e.preventDefault();
    this.registerTempState();
    this.setState({ currentState: pageState.EXPORTPAGE });
  }

  registerTempState() {
    const past = this.state.currentState;
    if (
      past !== pageState.EXPORTPAGE &&
      past !== pageState.IMPORTMODEL &&
      past !== pageState.PROCESS
    ) {
      if (past === pageState.EDITISSUE) {
        this.setState({ tempState: pageState.ISSUELIST });
      } else {
        this.setState({ tempState: past });
      }
    }
  }

  handleGotoProcessPage = () => {
    this.registerTempState();
    this.setState({ currentState: pageState.PROCESS });
  };

  render() {
    const lang = window.isGerman ? de : en;
    const { currentState } = this.state;

    return (
      <div className="manager_container">
        <div className="manager_header">
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <label>{"<"}</label>
            <button
              className="transparent_button"
              onClick={this.handleGotoProjects}
            >
              {lang["Projects"]}
            </button>
          </div>
          <button
            className="transparent_button"
            onClick={this.handleGotoModelPage}
          >
            {lang["Models"]}
          </button>
          <button
            className="transparent_button"
            onClick={this.handleGotoIssuePage}
          >
            {lang["Issues"]}
          </button>
          <button
            className="transparent_button"
            onClick={this.handleGotoExportPage}
          >
            Export
          </button>
          <button
            className="transparent_button"
            onClick={this.handleGotoProcessPage}
          >
            {lang["Process"]}
          </button>
          <div className="image_button">
            <img src={logoutImg} alt="" />
            <button className="transparent_button" onClick={this.handleLogout}>
              {lang["Logout"]}
            </button>
          </div>
        </div>
        <div className="manager_page">
          {currentState === pageState.IMPORTMODEL && (
            <ModelPage onSetPage={this.handleChangeIssuePage} />
          )}
          {currentState === pageState.ISSUELIST && (
            <IssueList onSetPage={this.handleChangeIssuePage} />
          )}
          {currentState === pageState.DETAILISSUE && (
            <IssueDetail onSetPage={this.handleChangeIssuePage} />
          )}
          {currentState === pageState.FILTERISSUE && (
            <IssueFilter onSetPage={this.handleChangeIssuePage} />
          )}
          {currentState === pageState.EDITISSUE && (
            <IssueEdit onSetPage={this.handleChangeIssuePage} />
          )}
          {currentState === pageState.EXPORTPAGE && <ExportPage />}
          {currentState === pageState.PROCESS && <EditProcess />}
        </div>
      </div>
    );
  }
}

const mapDispatchToState = (state) => {
  return {
    project: state.project.selectedProject,
    issue: state.detail.selectedIssue,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: (history) => {
      dispatch(userActions.logout(history));
    },
    returnProjects: (history) => {
      dispatch(projActions.returnProjects(history));
    },
    syncProject: (projId) => {
      dispatch(syncActions.syncProject(projId));
    },
  };
};

export default connect(mapDispatchToState, mapDispatchToProps)(IssueManager);
