import { apiService } from '../../services';
import { detailConstants, issuesConstants, projConstants } from '../constants';

export const issuesActions = {
    getIssues,
    setOwner,
    clearSelectedIssue,
    resetOwner,
    registerIssues,
    resetAddedIssueFlag
}

function getIssues(ids) {
    return dispatch => {
        apiService.getArrayData('issue', ids)
            .then(
                issues => {
                    dispatch(registerIssues(issues));
                    dispatch(clearSelectedIssue());
                }
            ).catch(error => {
                console.log('Fail getting issues:', error.message);
            });
    };
}

function registerIssues(issues) {
    return {
        type: issuesConstants.ISSUES_GETALL,
        payload: issues
    };
}

function setOwner(owner) {
    return {
        type: issuesConstants.ISSUES_OWNER,
        payload: owner
    }
}

function clearSelectedIssue() {
    return {
        type: detailConstants.DETAIL_CLEAR
    };
}

function resetOwner() {
    return {
        type: issuesConstants.ISSUES_RESET_OWNER
    };
}

function resetAddedIssueFlag() {
    return {
        type: projConstants.PROJ_RESETISSUEFLAG
    };
}