import React from "react";
import VisoSelect from "./viso_select";
import en from "../../assets/en.json";
import de from "../../assets/de.json";
import "../../css/processItem.css";

class ProcessResultItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = { acPropertyNames: [], defValue: undefined };
  }

  componentDidMount() {
    this.setPropertyOptions();
  }

  setPropertyOptions() {
    const lang = window.isGerman ? de : en;
    const process = this.props.result;
    let acPropertyNames = [
      { value: "new_property", label: lang["SetNewProp"] },
    ];
    const properties = process.acPropertyNames.map((n) => {
      return { value: n.guid, label: n.propertyName };
    });

    const defValue = this.props.newProperty;
    if (defValue) {
      properties.push({ value: defValue, label: defValue });
    }

    acPropertyNames = [...acPropertyNames, ...properties];

    this.setState({ acPropertyNames, defValue });
  }

  onSelectProperty = (guid) => {
    const property = this.props.result;
    this.props.onSetDefinitionGuid(guid, property);
  };

  render() {
    const lang = window.isGerman ? de : en;
    const { result } = this.props;
    const { acPropertyNames, defValue } = this.state;

    return (
      <div className="processItem">
        <div className="processItem_subContent">
          <label>Property</label>
          <div>{result.propertyName}</div>
        </div>
        <div className="processItem_subContent">
          <label>Pset</label>
          <div>{result.propertySetName}</div>
        </div>
        <div className="processItem_subContent">
          <label>{lang["NewAttribute"]}</label>
          <div style={{ width: "70%" }}>
            <VisoSelect
              options={acPropertyNames}
              onChange={this.onSelectProperty}
              isSearchable={true}
              defaultValue={defValue}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ProcessResultItem;
