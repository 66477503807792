import { apiService } from "../../services";
import { metaDataConstants } from "../constants";

export const metaDataActions = {
    getDisciplines,
    getBuildings,
    getFloors,
}

function getDisciplines(ids) {
    return dispatch => {
        apiService.getArrayData('metaData/discipline', ids)
            .then(disciplines => {
                dispatch(setDisciplines(disciplines));
            }).catch(err => {
                console.log(err.message);
            });
    }

    function setDisciplines(disciplines) {
        return {
            type: metaDataConstants.DISCIPLINE,
            payload: disciplines
        };
    }
}

function getBuildings(ids) {
    return dispatch => {
        apiService.getArrayData('metaData/building', ids)
            .then(buildings => {
                dispatch(setBuildings(buildings));
            }).catch(err => {
                console.log(err.message);
            });
    }

    function setBuildings(buildings) {
        return {
            type: metaDataConstants.BUILDING,
            payload: buildings
        };
    }
}

function getFloors(ids) {
    return dispatch => {
        apiService.getArrayData('metaData/floor', ids)
            .then(floors => {
                dispatch(setFloors(floors));
            }).catch(err => {
                console.log(err.message);
            });
    }

    function setFloors(floors) {
        return {
            type: metaDataConstants.FLOOR,
            payload: floors
        };
    }
}