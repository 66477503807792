import React from "react";
import { connect } from "react-redux";
import { filterActions, issuesActions } from "../redux/actions";
import { pageState } from "./enums";
import { converters } from "./helper";
import VisoSelect from "./controls/viso_select";
import VisoDatePicker from "./controls/viso_datepicker";
//import { filterConstants } from "../redux/constants";
import en from "../assets/en.json";
import de from "../assets/de.json";
import "../css/issueFilter.css";

class IssueFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buildings: [],
      floors: [],
      disciplines: [],
      tags: [],
      workStages: [],
      sources: [],
      filterData: {},
      statuses: [],
      priorities: [],
      types: [],
      users: [],
    };
  }

  componentDidMount() {
    if (this.props.project.id) {
      this.setFilterData();
      this.retrieveData();
    }
  }

  setFilterData() {
    const filterDto = this.props.filterData;
    const filterData = {
      createdFrom: undefined,
      createdTo: undefined,
      dueDateFrom: undefined,
      dueDateTo: undefined,
      startDateFrom: undefined,
      startDateTo: undefined,
      editDateFrom: undefined,
      editDateTo: undefined,
      status: [],
      priority: "All",
      source: "All",
      type: "All",
      building: "All",
      floor: "All",
      discipline: "All",
      workStage: "All",
      tag: "All",
      assigner: "All",
      reviewer: "All",
      creator: "All",
      editor: "All",
    };

    if (filterDto) {
      filterData.createdFrom = filterDto.creationDateAfter;
      filterData.createdTo = filterDto.creationDateBefore;
      filterData.dueDateFrom = filterDto.dueDateAfter;
      filterData.dueDateTo = filterDto.dueDateBefore;
      filterData.startDateFrom = filterDto.startDateAfter;
      filterData.startDateTo = filterDto.startDateBefore;
      filterData.editDateFrom = filterDto.editDateAfter;
      filterData.editDateTo = filterDto.editDateBefore;

      if (filterDto.statusIds && filterDto.statusIds.length)
        filterData.status = filterDto.statusIds;
      if (filterDto.priorityId) filterData.priority = filterDto.priorityId;
      if (filterDto.typeId) filterData.type = filterDto.typeId;
      if (filterDto.source) filterData.source = filterDto.source;
      if (filterDto.workPhaseId) filterData.workStage = filterDto.workPhaseId;
      if (filterDto.tagIds && filterDto.tagIds.length)
        filterData.tag = filterDto.tagIds[0];
      if (filterDto.buildingMetadataIds && filterDto.buildingMetadataIds.length)
        filterData.building = filterDto.buildingMetadataIds[0];
      if (filterDto.floorMetaDataIds && filterDto.floorMetaDataIds.length)
        filterData.floor = filterDto.floorMetaDataIds[0];
      if (
        filterDto.disciplineMetaDataIds &&
        filterDto.disciplineMetaDataIds.length
      )
        filterData.discipline = filterDto.disciplineMetaDataIds[0];
      if (filterDto.assignedUserIds && filterDto.assignedUserIds.length)
        filterData.assigner = filterDto.assignedUserIds[0];
      if (filterDto.createAuthorId)
        filterData.creator = filterDto.createAuthorId;
      if (filterDto.reviewerId) filterData.reviewer = filterDto.reviewerId;
      if (filterDto.editAuthorId) filterData.editor = filterDto.editAuthorId;
    }

    this.setState({ filterData });
  }

  retrieveData() {
    const { project, issues } = this.props;

    if (project) {
      const statuses = this.getStatusOptions();
      const priorities = this.getPriorityOptions();
      const types = this.getTypeOptions();
      const users = this.getUserOptions();

      this.setState({
        buildings: converters.getOptionsFromMetaData(
          this.props.buildings,
          true
        ),
        floors: converters.getOptionsFromMetaData(this.props.floors, true),
        disciplines: converters.getOptionsFromMetaData(
          this.props.disciplines,
          true
        ),
        tags: converters.getOptionsFromTag(this.props.project.tags, true),
        workStages: converters.getOptionsFromWorkphase(
          this.props.project.workPhases,
          true
        ),
        sources: getSources(),
        statuses,
        priorities,
        types,
        users,
      });
    }

    function getSources() {
      const lang = window.isGerman ? de : en;
      let sources = [{ value: "All", label: lang["All"] }];

      if (issues) {
        sources = [...sources, ...getData()];
      }

      return sources;

      function getData() {
        let sources = issues
          .map((i) => {
            return i.source;
          })
          .filter((s) => s && s !== "");

        sources = [...new Set(sources)].map((s) => {
          return { value: s, label: s };
        });

        return sources;
      }
    }
  }

  getStatusOptions() {
    const statuses = this.props.statuses.map((s) => {
      return { value: s.id, label: s.name };
    });

    return statuses;
  }

  getPriorityOptions() {
    const lang = window.isGerman ? de : en;
    let options = [{ value: "All", label: lang["All"] }];
    const priorities = this.props.priorities.map((s) => {
      return { value: s.id, label: s.name };
    });

    options = [...options, ...priorities];

    return options;
  }

  getTypeOptions() {
    const lang = window.isGerman ? de : en;
    let options = [{ value: "All", label: lang["All"] }];
    const types = this.props.types.map((s) => {
      return { value: s.id, label: s.name };
    });

    options = [...options, ...types];

    return options;
  }

  getUserOptions() {
    const lang = window.isGerman ? de : en;
    let options = [{ value: "All", label: lang["All"] }];
    const users = this.props.project.userPreviews.map((u) => {
      return { value: u.id, label: `${u.firstName} ${u.lastName}` };
    });

    options = [...options, ...users];

    return options;
  }

  handleGoBack = () => {
    this.props.onSetPage(pageState.ISSUELIST);
  };

  handleReset = () => {
    //if (this.props.filterData) this.props.resetFilter(this.props.filterData.id);
    this.props.resetFilter();
    this.props.onSetPage(pageState.ISSUELIST);
  };

  handleApply = () => {
    /*
    const persistDto = {};
    if (this.props.filterData) persistDto.id = this.props.filterData.id;
    persistDto.type = 1;
    persistDto.isPrivate = true;
    persistDto.projectId = this.props.project.id;
    persistDto.name = { value: filterConstants.FILTER_NAME };
    persistDto.query = { value: "string" };
    persistDto.uiFilterSettings = {
      value: { issueFilter: this.getIssueFilterSettings() },
    };

    this.props.applyFilter(persistDto);
    */

    this.props.applyFilter(this.getIssueFilterSettings());
    this.props.onSetPage(pageState.ISSUELIST);
  };

  getIssueFilterSettings() {
    const filterData = this.state.filterData;
    const issueFilter = {};
    issueFilter.creationDateAfter = filterData.createdFrom;
    issueFilter.creationDateBefore = filterData.createdTo;
    issueFilter.dueDateAfter = filterData.dueDateFrom;
    issueFilter.dueDateBefore = filterData.dueDateTo;
    issueFilter.startDateAfter = filterData.startDateFrom;
    issueFilter.startDateBefore = filterData.startDateTo;
    issueFilter.editDateAfter = filterData.editDateFrom;
    issueFilter.editDateBefore = filterData.editDateTo;
    if (filterData.status) issueFilter.statusIds = [...filterData.status];
    if (filterData.priority !== "All")
      issueFilter.priorityId = filterData.priority;
    if (filterData.source !== "All") issueFilter.source = filterData.source;
    if (filterData.type !== "All") issueFilter.typeId = filterData.type;
    if (filterData.workStage !== "All")
      issueFilter.workPhaseId = filterData.workStage;
    if (filterData.tag !== "All") issueFilter.tagIds = [filterData.tag];
    if (filterData.building !== "All")
      issueFilter.buildingMetadataIds = [filterData.building];
    if (filterData.floor !== "All")
      issueFilter.floorMetaDataIds = [filterData.floor];
    if (filterData.discipline !== "All")
      issueFilter.disciplineMetaDataIds = [filterData.discipline];
    if (filterData.assigner !== "All")
      issueFilter.assignedUserIds = [filterData.assigner];
    if (filterData.creator !== "All")
      issueFilter.createAuthorId = filterData.creator;
    if (filterData.reviewer !== "All")
      issueFilter.reviewerId = filterData.reviewer;
    if (filterData.editor !== "All")
      issueFilter.editAuthorId = filterData.editor;

    return issueFilter;
  }

  changeStatus = (status) => {
    let filterData = this.state.filterData;
    filterData.status = status;
    this.setState({ filterData: filterData });
  };

  changePriority = (priority) => {
    let filterData = this.state.filterData;
    filterData.priority = priority;
    this.setState({ filterData: filterData });
  };

  changeSource = (source) => {
    let filterData = this.state.filterData;
    filterData.source = source;
    this.setState({ filterData: filterData });
  };

  changeType = (type) => {
    let filterData = this.state.filterData;
    filterData.type = type;
    this.setState({ filterData: filterData });
  };

  changeBuilding = (building) => {
    let filterData = this.state.filterData;
    filterData.building = building;
    this.setState({ filterData: filterData });
  };

  changeFloor = (floor) => {
    let filterData = this.state.filterData;
    filterData.floor = floor;
    this.setState({ filterData: filterData });
  };

  changeDiscipline = (discipline) => {
    let filterData = this.state.filterData;
    filterData.discipline = discipline;
    this.setState({ filterData });
  };

  changeWorkStage = (workStage) => {
    let filterData = this.state.filterData;
    filterData.workStage = workStage;
    this.setState({ filterData });
  };

  changeTag = (tag) => {
    let filterData = this.state.filterData;
    filterData.tag = tag;
    this.setState({ filterData });
  };

  changeCreatedFrom = (date) => {
    let filterData = this.state.filterData;
    filterData.createdFrom = date;
    this.setState({ filterData: filterData });
  };

  changeCreatedTo = (date) => {
    let filterData = this.state.filterData;
    filterData.createdTo = date;
    this.setState({ filterData: filterData });
  };

  changeDueDateFrom = (date) => {
    let filterData = this.state.filterData;
    filterData.dueDateFrom = date;
    this.setState({ filterData: filterData });
  };

  changeDueDateTo = (date) => {
    let filterData = this.state.filterData;
    filterData.dueDateTo = date;
    this.setState({ filterData: filterData });
  };

  changeStartDateFrom = (date) => {
    let filterData = this.state.filterData;
    filterData.startDateFrom = date;
    this.setState({ filterData });
  };

  changeStartDateTo = (date) => {
    let filterData = this.state.filterData;
    filterData.startDateTo = date;
    this.setState({ filterData });
  };

  changeEditDateFrom = (date) => {
    let filterData = this.state.filterData;
    filterData.editDateFrom = date;
    this.setState({ filterData });
  };

  changeEditDateTo = (date) => {
    let filterData = this.state.filterData;
    filterData.editDateTo = date;
    this.setState({ filterData });
  };

  changeCreator = (creator) => {
    let filterData = this.state.filterData;
    filterData.creator = creator;
    this.setState({ filterData });
  };

  changeReviewer = (reviewer) => {
    let filterData = this.state.filterData;
    filterData.reviewer = reviewer;
    this.setState({ filterData });
  };

  changeAssigner = (assigner) => {
    let filterData = this.state.filterData;
    filterData.assigner = assigner;
    this.setState({ filterData });
  };

  changeEditor = (editor) => {
    let filterData = this.state.filterData;
    filterData.editor = editor;
    this.setState({ filterData });
  };

  render() {
    const lang = window.isGerman ? de : en;
    const {
      buildings,
      floors,
      disciplines,
      tags,
      workStages,
      sources,
      filterData,
      statuses,
      priorities,
      types,
      users,
    } = this.state;
    const { project } = this.props;

    return (
      <div className="filter_container">
        <div className="filter_header">
          <h2>{lang["Issues"]}</h2>
          <div className="rightSide">
            <label>{project.name}</label>
          </div>
        </div>
        <div className="filter_back">
          <label>{"<"}</label>
          <button
            className="transparent_button"
            style={{ textDecoration: "underline" }}
            onClick={this.handleGoBack}
          >
            {lang["Back"]}
          </button>
        </div>
        <div className="filter_label">{lang["Filter"]}</div>
        <div className="filter_body scrollbar">
          <div>
            <div className="subtitle mb-0">{lang["Date"]}</div>
            <div className="subtitle subtitle--small">
              {lang["DateCreated"]}
            </div>
          </div>
          <div className="filter_grid mt-0">
            <div>
              <label htmlFor="createdFrom">{lang["CreatedAfter"]}</label>
              <VisoDatePicker
                id="createdFrom"
                value={filterData.createdFrom}
                onChange={this.changeCreatedFrom}
              ></VisoDatePicker>
            </div>
            <div>
              <label htmlFor="createdTo">{lang["CreatedBefore"]}</label>
              <VisoDatePicker
                id="createdTo"
                value={filterData.createdTo}
                onChange={this.changeCreatedTo}
              ></VisoDatePicker>
            </div>
          </div>
          <div>
            <div className="subtitle subtitle--small">{lang["StartDate"]}</div>
          </div>
          <div className="filter_grid mt-0">
            <div>
              <label htmlFor="startDateFrom">{lang["StartAfter"]}</label>
              <VisoDatePicker
                id="StartDateFrom"
                value={filterData.startDateFrom}
                onChange={this.changeStartDateFrom}
              ></VisoDatePicker>
            </div>
            <div>
              <label htmlFor="startDateTo">{lang["StartBefore"]}</label>
              <VisoDatePicker
                id="startDateTo"
                value={filterData.startDateTo}
                onChange={this.changeStartDateTo}
              ></VisoDatePicker>
            </div>
          </div>
          <div>
            <div className="subtitle subtitle--small">{lang["DueDate"]}</div>
          </div>
          <div className="filter_grid mt-0">
            <div>
              <label htmlFor="dueDataFrom">{lang["DueDateAfter"]}</label>
              <VisoDatePicker
                id="dueDataFrom"
                value={filterData.dueDateFrom}
                onChange={this.changeDueDateFrom}
              ></VisoDatePicker>
            </div>
            <div>
              <label htmlFor="dueDateTo">{lang["DueDateBefore"]}</label>
              <VisoDatePicker
                id="dueDateTo"
                value={filterData.dueDateTo}
                onChange={this.changeDueDateTo}
              ></VisoDatePicker>
            </div>
          </div>
          <div className="filter_group">
            <div className="subtitle mb-0">{lang["Issue"]}</div>
          </div>
          <div className="filter_grid mt-4">
            <div>
              <label htmlFor="status">{lang["Status"]}</label>
              <VisoSelect
                id="status"
                options={statuses}
                onChange={this.changeStatus}
                defaultValue={filterData.status}
                isMultiSelect={true}
              />
            </div>
            <div>
              <label htmlFor="priority">{lang["Priority"]}</label>
              {priorities && (
                <VisoSelect
                  id="priority"
                  options={priorities}
                  onChange={this.changePriority}
                  defaultValue={filterData.priority}
                />
              )}
            </div>
          </div>
          <div className="filter_grid mt-4">
            <div>
              <label htmlFor="assignedto">{lang["AssignedTo"]}</label>
              <VisoSelect
                id="assignedto"
                options={users}
                onChange={this.changeAssigner}
                defaultValue={filterData.assigner}
              />
            </div>
            <div>
              <label htmlFor="reviewer">{lang["Reviewer"]}</label>
              <VisoSelect
                id="reviewer"
                options={users}
                onChange={this.changeReviewer}
                defaultValue={filterData.reviewer}
              />
            </div>
          </div>
          <div className="filter_grid mt-4">
            <div>
              <label htmlFor="creator">{lang["CreatorFlt"]}</label>
              <VisoSelect
                id="creator"
                options={users}
                onChange={this.changeCreator}
                defaultValue={filterData.creator}
              />
            </div>
            <div>
              <label htmlFor="source">{lang["Source"]}</label>
              <VisoSelect
                id="source"
                options={sources}
                onChange={this.changeSource}
                defaultValue={filterData.source}
              />
            </div>
          </div>
          <div className="filter_group">
            <div className="subtitle mb-0">Detail</div>
          </div>
          <div className="filter_grid mt-4">
            <div>
              <label htmlFor="discipline">{lang["Discipline"]}</label>
              <VisoSelect
                id="discipline"
                options={disciplines}
                onChange={this.changeDiscipline}
                defaultValue={filterData.discipline}
              />
            </div>
            <div>
              <label htmlFor="floor">{lang["Floor"]}</label>
              <VisoSelect
                id="floor"
                options={floors}
                onChange={this.changeFloor}
                defaultValue={filterData.floor}
              />
            </div>
          </div>
          <div className="filter_grid mt-4">
            <div>
              <label htmlFor="building">{lang["Building"]}</label>
              <VisoSelect
                id="building"
                options={buildings}
                onChange={this.changeBuilding}
                defaultValue={filterData.building}
              />
            </div>
            <div>
              <label htmlFor="workstage">{lang["Workstage"]}</label>
              <VisoSelect
                id="workStage"
                options={workStages}
                onChange={this.changeWorkStage}
                defaultValue={filterData.workStage}
              />
            </div>
          </div>
          <div className="filter_grid mt-4">
            <div>
              <label htmlFor="tag">{lang["Tag"]}</label>
              <VisoSelect
                id="tag"
                options={tags}
                onChange={this.changeTag}
                defaultValue={filterData.tag}
              />
            </div>
            <div>
              <label htmlFor="type">{lang["Type"]}</label>
              <VisoSelect
                id="type"
                options={types}
                onChange={this.changeType}
                defaultValue={filterData.type}
              />
            </div>
          </div>
          <div className="filter_group">
            <div>
              <div className="subtitle mb-0">{lang["Edit"]}</div>
              <div className="subtitle subtitle--small">{lang["EditDate"]}</div>
            </div>
            <div className="filter_grid mt-4">
              <div>
                <label htmlFor="editfrom">{lang["EditedAfter"]}</label>
                <VisoDatePicker
                  id="editfrom"
                  value={filterData.editDateFrom}
                  onChange={this.changeEditDateFrom}
                ></VisoDatePicker>
              </div>
              <div>
                <label htmlFor="editto">{lang["EditedBefore"]}</label>
                <VisoDatePicker
                  id="editto"
                  value={filterData.editDateTo}
                  onChange={this.changeEditDateTo}
                ></VisoDatePicker>
              </div>
            </div>
          </div>
          <div className="filter_grid mt-4">
            <div>
              <label htmlFor="lastedit">{lang["LastEdited"]}</label>
              <VisoSelect
                id="lastedit"
                options={users}
                onChange={this.changeEditor}
                defaultValue={filterData.editor}
              />
            </div>
          </div>
        </div>
        <div className="filter_grid" style={{ gridRow: "5" }}>
          <button
            className="visoplan_button"
            style={{ width: "100%" }}
            onClick={this.handleReset}
          >
            {lang["ResetFilter"]}
          </button>
          <button
            className="visoplan_Actbutton"
            style={{ width: "100%" }}
            onClick={this.handleApply}
          >
            {lang["ApplyFilter"]}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    project: state.project.selectedProject,
    issues: state.issues.issues,
    filterData: state.filter.filterData,
    buildings: state.metaData.buildings,
    floors: state.metaData.floors,
    disciplines: state.metaData.disciplines,
    statuses: state.issueDescriptor.statuses,
    priorities: state.issueDescriptor.priorities,
    types: state.issueDescriptor.types,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetFilter: (id) => {
      dispatch(filterActions.resetFilter(id));
    },
    applyFilter: (data) => {
      dispatch(filterActions.applyFilter(data));
    },
    resetOwner: () => {
      dispatch(issuesActions.resetOwner());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IssueFilter);
