import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { processActions } from "../redux/actions";
import { ProcessModel } from "./models/ProcessModel";
import ProcessItem from "./controls/processItem";
import ProcessResultItem from "./controls/processresultItem";
import { processStatus } from "./enums";
import en from "../assets/en.json";
import de from "../assets/de.json";
import "../css/editProcess.css";

const initialImportResult = {
  succeed: 0,
  failed: 0,
  isResult: true,
};

const FailedProcessReport = ({ failedResult }) => {
  const [lang, setLang] = useState(en);
  const [noElementContent, setNoElementContent] = useState("");
  const [readOnlyContent, setReadOnlyContent] = useState("");
  const [notMatchedContent, setNotMatchedContent] = useState("");
  const [notRecogContent, setNotRecogContent] = useState("");

  useEffect(() => {
    setLang(window.isGerman ? de : en);
  }, []);

  useEffect(() => {
    const noElements = failedResult.filter(
      (r) => r.error === processStatus.NoElement
    ).length;
    const readOnlys = failedResult.filter(
      (r) => r.error === processStatus.ReadOnly
    ).length;
    const notMatcheds = failedResult.filter(
      (r) => r.error === processStatus.NotMatched
    ).length;
    const notRecogs = failedResult.filter(
      (r) => r.error === processStatus.NotRecog
    ).length;

    if (noElements) {
      setNoElementContent(lang["NoElementReport"].replace("{%}", noElements));
    }

    if (readOnlys) {
      setReadOnlyContent(lang["ReadOnlyReport"].replace("{%}", readOnlys));
    }

    if (notMatcheds) {
      setNotMatchedContent(
        lang["NotMatchedReport"].replace("{%}", notMatcheds)
      );
    }

    if (notRecogs) {
      setNotRecogContent(lang["NotRecogReport"].replace("{%}", notRecogs));
    }
  }, [failedResult, lang]);

  return (
    <div>
      <div>{noElementContent}</div>
      <div>{readOnlyContent}</div>
      <div>{notMatchedContent}</div>
      <div>{notRecogContent}</div>
    </div>
  );
};

class EditProcess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processList: [],
      isResult: false,
      processResult: [],
      importResult: initialImportResult,
    };

    this.history = [];

    window.EditProcess = this;
  }

  componentDidMount() {
    if (this.props.project.id) {
      this.getProcessHistory();
    }

    this.timerId = setInterval(() => {
      this.getProcessDtos();
    }, 60000);
  }

  componentWillUnmount() {
    window.saveProcessHistoryData();
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.processes !== prevProps.processes) {
      this.getProcessModels();
    }
  }

  getProcessHistory() {
    window.retrieveProcessHistory(this.props.project.id);
  }

  registerProcessHistory(history) {
    if (history) {
      this.history = JSON.parse(history);
    }

    this.getProcessDtos();
  }

  getProcessDtos() {
    if (this.props.project.processIds) {
      this.props.getProcessDtos(this.props.project.processIds);
    }
  }

  getProcessModels() {
    const origin = this.state.processList;

    const newProcesses = this.props.processes.filter(
      (p) => !origin.map((l) => l.id).includes(p.id)
    );
    const processList = newProcesses.map((p) => {
      const data = new ProcessModel(p);

      data.model = this.props.models.find(
        (m) =>
          m.disciplineMetaDataId === p.disciplineMetaDataId &&
          m.buildingMetaDataId === p.buildingMetaDataId &&
          m.floorMetaDataId === p.floorMetaDataId
      )?.name;
      data.discipline = this.props.disciplines.find(
        (d) => d.id === p.disciplineMetaDataId
      )?.name;
      data.building = this.props.buildings.find(
        (b) => b.id === p.buildingMetaDataId
      )?.name;
      data.floor = this.props.floors.find(
        (f) => f.id === p.floorMetaDataId
      )?.name;

      const history = this.history.find((h) => h.id === p.id);
      if (history) {
        data.user = history.user;
        data.imported = true;
        data.importedDate = new Date(history.date);
      } else {
        data.user = `${p.reviewer?.firstName} ${p.reviewer?.lastName}`;
      }

      data.description = this.getDescription(data);
      return data;
    });

    this.setState({ processList: [...origin, ...processList] });
  }

  getDescription(data) {
    let desc = "";
    if (data.imported) {
      const diff = Math.floor(
        (Date.now() - Date.parse(data.importedDate)) / (24 * 3600 * 1000)
      );
      desc =
        diff === 0
          ? `Imported today by ${data.user}`
          : `Imported ${diff} days ago by ${data.user}`;
      if (window.isGerman) {
        desc =
          diff === 0
            ? `von ${data.user} heute importiert`
            : `von ${data.user} vor ${diff} Tagen importiert`;
      }
    } else {
      const diff = Math.floor(
        (Date.now() - Date.parse(data.process.reviewDate)) / (24 * 3600 * 1000)
      );
      desc =
        diff === 0
          ? `Approved today by ${data.user}`
          : `Approved ${diff} days ago by ${data.user}`;
      if (window.isGerman) {
        desc =
          diff === 0
            ? `von ${data.user} heute genehmigt`
            : `von ${data.user} vor ${diff} Tagen genehmigt`;
      }
    }

    return desc;
  }

  registerImportedResult(processId, result) {
    if (result) {
      const processList = this.state.processList;
      const index = processList.findIndex((p) => p.id === processId);
      if (index > -1) {
        const data = JSON.parse(result);
        const isResult = true;
        if (data.findIndex((e) => e.error === processStatus.NoError) > -1) {
          processList[index].imported = true;
          processList[index].importedDate = new Date();
          processList[index].description = this.getDescription(
            processList[index]
          );
        }

        const failedProcesses = data
          .filter((e) => e.error > processStatus.NoError)
          .map((r) => {
            r.processId = processId;
            r.isNew = false;
            return r;
          });

        const succeed = data.filter(
          (e) => e.error === processStatus.NoError
        ).length;
        const failed = failedProcesses.length;

        const importResult = { succeed, failed, isResult: true };
        this.setState({
          isResult,
          processList,
          processResult: failedProcesses,
          importResult,
        });
      }
    } else {
      const lang = window.isGerman ? de : en;
      window.AlertAPI(lang["FailImportProcess"]);
    }
  }

  registerNewAttributeResult(processId, result) {
    const lang = window.isGerman ? de : en;
    if (result) {
      const processList = this.state.processList;
      const index = processList.findIndex((p) => p.id === processId);
      if (index > -1) {
        processList[index].imported = true;
        processList[index].importedDate = new Date();
        processList[index].description = this.getDescription(
          processList[index]
        );

        this.setState({ processList });
      }

      window.AlertAPI(lang["SuccessImportProcess"]);
    } else {
      window.AlertAPI(lang["FailImportProcess"]);
    }
  }

  onMatchProperties = () => {
    this.setState({ isResult: false, importResult: initialImportResult });
    const newAttributes = this.state.processResult.filter(
      (r) => r.definitionGuid || r.isNew
    );
    if (newAttributes.length) {
      let model = {};
      model.id = newAttributes[0].processId;
      model.user = `${this.props.user.firstName} ${this.props.user.lastName}`;
      model.data = newAttributes;

      window.setNewAttribute(model);
    }
  };

  renderProcessList() {
    return this.state.processList.map((p, index) => {
      const k = Math.floor(Math.random() * 65535) + index; // Force render
      return <ProcessItem process={p} key={k} />;
    });
  }

  processListElement() {
    const { processList } = this.state;
    return (
      <div className="processList_body">
        {processList && this.renderProcessList()}
      </div>
    );
  }

  onChangeNewAttribute = (guid, process) => {
    if (guid === "new_property") {
      const propertyNames = process.acPropertyNames.map(
        (ac) => ac.propertyName
      );

      window.getNewPropertyName(this.getKeyOfProcess(process), propertyNames);
    } else {
      let processResult = this.state.processResult;

      processResult.forEach((p) => {
        if (this.getKeyOfProcess(p) === this.getKeyOfProcess(process)) {
          p.definitionGuid = guid;
        }
      });

      this.setState({ processResult });
    }
  };

  setNewPropertyName(key, newPropName) {
    if (newPropName) {
      let processResult = this.state.processResult;

      processResult.forEach((p) => {
        if (this.getKeyOfProcess(p) === key) {
          p.isNew = true;
          p.newProperty = newPropName;
        }
      });

      this.setState({ processResult });
    }
  }

  renderProcessResult() {
    const propertyMap = this.getPropertyMap();
    return Object.keys(propertyMap).map((key) => {
      const process = propertyMap[key];
      return (
        <ProcessResultItem
          result={process}
          key={`${process.newProperty}_${key}`}
          newProperty={process.newProperty}
          onSetDefinitionGuid={(guid, p) => this.onChangeNewAttribute(guid, p)}
        />
      );
    });
  }

  getPropertyMap() {
    const propertyMap = {};
    this.state.processResult
      .filter(
        (p) =>
          p.error === processStatus.NotMatched ||
          p.error === processStatus.NotRecog ||
          p.isNew
      )
      .forEach((p) => {
        const key = this.getKeyOfProcess(p);
        if (!Object.keys(propertyMap).includes(key)) {
          propertyMap[key] = p;
        }
      });

    return propertyMap;
  }

  getKeyOfProcess(process) {
    return `${process.propertyName}_${process.propertySetName}`;
  }

  onAssignedProperty = () => {
    const { importResult } = this.state;
    if (importResult.failed === 0 || !this.canAssignProperty()) {
      this.setState({ isResult: false, importResult: initialImportResult });
    } else {
      importResult.isResult = false;
      this.setState({ importResult });
    }
  };

  getImportResultContent = (isSucceeded, count) => {
    const lang = window.isGerman ? de : en;
    const content = isSucceeded
      ? lang["SucceedPropertyMatching"]
      : lang["FailedPropertyMatching"];
    return content.replace("{%}", count);
  };

  canAssignProperty = () => {
    const { processResult } = this.state;
    const editableIndex = processResult.findIndex(
      (p) =>
        p.error === processStatus.NotMatched ||
        p.error === processStatus.NotRecog
    );

    return editableIndex > -1;
  };

  processResultElement() {
    const lang = window.isGerman ? de : en;
    const { processResult, importResult } = this.state;
    return (
      <div className="processresult">
        <h2 className="processresult-title">
          {importResult.isResult ? lang["AssignProp"] : lang["ImportProcess"]}
        </h2>
        {importResult.isResult ? (
          <>
            <div className="processList_body scrollbar">
              {importResult.succeed ? (
                <div style={{ marginLeft: "8px" }}>
                  {this.getImportResultContent(true, importResult.succeed)}
                </div>
              ) : (
                <></>
              )}
              {importResult.failed ? (
                <div style={{ marginLeft: "8px" }}>
                  <div style={{ color: "red" }}>
                    {this.getImportResultContent(false, importResult.failed)}
                  </div>
                  <FailedProcessReport failedResult={processResult} />
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="processresult-bottom">
              <button
                className="visoplan_Actbutton"
                style={{ width: "200px" }}
                onClick={this.onAssignedProperty}
              >
                {this.canAssignProperty() ? lang["AssignProp"] : lang["GoBack"]}
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="processList_body scrollbar">
              {processResult && this.renderProcessResult()}
            </div>
            <div className="processresult-bottom">
              <button
                className="visoplan_Actbutton"
                style={{ width: "200px" }}
                onClick={this.onMatchProperties}
              >
                {lang["MatchProp"]}
              </button>
            </div>
          </>
        )}
      </div>
    );
  }

  render() {
    const lang = window.isGerman ? de : en;
    const { isResult } = this.state;

    return (
      <div className="processList_Container">
        <div className="processList_header">
          <h2>{lang["Process"]}</h2>
          <label>{this.props.project.name}</label>
        </div>
        {isResult ? this.processResultElement() : this.processListElement()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    project: state.project.selectedProject,
    buildings: state.metaData.buildings,
    disciplines: state.metaData.disciplines,
    floors: state.metaData.floors,
    models: state.models.models,
    processes: state.processes.processes,
    user: state.user.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProcessDtos: (ids) => {
      dispatch(processActions.getProcesses(ids));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProcess);
