import React, { createRef } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretUp,
  faTimes,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import Moment from "moment";
import CheckImg from "../assets/check.svg";
import { converters, getter } from "./helper";
import { exportType, roleAction } from "./enums";
import VisoSelect from "./controls/viso_select";
import { apiService } from "../services";
import { TreeSelect } from "antd";
import { CaretDownFilled } from "@ant-design/icons";
import en from "../assets/en.json";
import de from "../assets/de.json";
import "../css/exportOther.css";
import "antd/dist/antd.css";
import CreateNameSelect from "./controls/CreateNameSelect";
import { Backdrop, CircularProgress } from "@material-ui/core";

library.add(faCaretDown, faCaretUp, faTimes, faTimesCircle);
const fileFormats = converters.getOptionsFromEnum(exportType);

class ExportOther extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreated: false,
      isHover: false,
      exportFormat: exportType.DWG.value,
      docDatas: [],
      folder: undefined,
      treeData: [],
      docNames: [],
      docName: undefined,
      exportHistories: [],
      checkResult: undefined,
      isExportEnable: false,
      isExporting: false,
    };

    this.nameRef = createRef();
    window.exportOther = this;
  }

  componentDidMount() {
    this.initialize();
    this.requestExportHistory();
    // #TODO use signalR or find other solution.
    // this.timerId = setInterval(() => {
    //     this.initialize();
    // }, 60000);
  }

  componentWillUnmount() {
    // #TODO use signalR or find other solution.
    // clearInterval(this.timerId);

    if (this.state.exportHistories.length) {
      window.saveExportHistory({
        projectId: this.props.project.id,
        data: JSON.stringify(this.state.exportHistories),
        isModel: false,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!!this.state.folder && prevState.folder !== this.state.folder) {
      this.getFileNamesInFolder(this.state.folder);
    }
  }

  requestExportHistory() {
    window.requestExportHistory({
      projectId: this.props.project.id,
      model: false,
    });
  }

  registerExportHistory(data) {
    if (!!data) {
      const histories = JSON.parse(data);
      if (!!histories) {
        this.setState({ exportHistories: histories });
      }
    }
  }

  async initialize() {
    await this.getFolderData();
  }

  async getFolderData() {
    await apiService
      .getFolderData()
      .then((folderData) => {
        if (folderData.data) {
          this.getTreeData(folderData.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getFileNamesInFolder(folderId) {
    const endpoint = `documentVersion?filter=folderId eq '${folderId}'`;
    apiService
      .getDataByEndpoint(endpoint)
      .then((res) => {
        let names = res.data.map((d) =>
          d.name.split(".").slice(0, -1).join(".")
        );
        names = [...new Set(names)];
        const docNames = names.map((n) => {
          return { value: n, label: n };
        });
        const docName = docNames.length ? docNames[0].value : undefined;
        this.setState({ docNames, docName });
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  handleMouseEnter = () => {
    this.setState({ isHover: true });
  };

  handleMouseLeave = () => {
    this.setState({ isHover: false });
  };

  handleDelete = () => {
    this.setState({ isCreated: false });
  };

  handleCreate = () => {
    if (this.state.isCreated === false) {
      window.Create2dPlan(this.state.exportFormat);
    }
  };

  async checkRoleAction() {
    const projects = await apiService.getArrayData("project", [
      this.props.project.id,
    ]);
    if (!projects || projects.length === 0) return false;
    const project = projects[0];
    const roleActions = getter.getRoleActions(project, this.props.user);
    if (!roleActions) return false;

    return getter.isActionAllowed(roleActions, roleAction.Document_Upload);
  }

  async canExport() {
    const isActionAllowed = await this.checkRoleAction();
    if (!isActionAllowed) {
      const lang = window.isGerman ? de : en;
      window.AlertAPI(lang["CantExportDocument"]);
    }
    return isActionAllowed;
  }

  handleExport = async () => {
    const isActionAllowed = await this.canExport();
    if (!isActionAllowed) return;

    const fileName = this.nameRef.current
      ? this.nameRef.current.value
      : undefined;
    if (this.state.isCreated && fileName && this.state.folder) {
      this.setState({ isExporting: true });
      window.Export2dPlan(this.generateExportData());
    }
  };

  generateExportData() {
    let exportData = {};
    exportData.projectId = this.props.project.id;
    exportData.folderId = this.state.folder;
    exportData.fileName = this.changeUmlaut(this.nameRef.current.value);
    exportData.token = getter.getTokenBearer();

    return JSON.stringify(exportData);
  }

  changeUmlaut(origin) {
    return origin
      .replace(/\u00dc/g, "UE")
      .replace(/\u00fc/g, "ue")
      .replace(/\u00c4/g, "AE")
      .replace(/\u00e4/g, "ae")
      .replace(/\u00d6/g, "OE")
      .replace(/\u00f6/g, "oe")
      .replace(/\u00df/g, "ss");
  }

  handleChangeFileFormat = (fileFormat) => {
    this.setState({ exportFormat: fileFormat });
  };

  onFolderChange = (folder) => {
    this.setState({ folder, isExportEnable: false, checkResult: undefined });
  };

  onNameChange = () => {
    this.setState({ isExportEnable: false, checkResult: undefined });
  };

  onUploadCheck = async () => {
    const isActionAllowed = await this.canExport();
    if (!isActionAllowed) return;

    const fileName = this.nameRef.current?.value || "";
    if (fileName) {
      const endpoint = `document/check?folderId=${this.state.folder}`;
      apiService
        .postData(endpoint, [this.addFileExtension(fileName)])
        .then((res) => {
          const { precheckResults } = res.data;
          if (precheckResults.length) {
            const preCheckResult = precheckResults[0];
            if (preCheckResult.success) {
              const checkResult = {
                success: true,
                cleanFileName: preCheckResult.fileName,
                predictedVersion: preCheckResult.predictedVersion,
                namingSchemeName: preCheckResult.namingSchemeName,
              };
              this.setState({ checkResult, isExportEnable: true });
            } else {
              if (preCheckResult.namingSchemeResults.length) {
                var result = preCheckResult.namingSchemeResults.find(
                  (nsr) => nsr.success === false
                );
                if (!result.cleanFileName)
                  result.cleanFileName = preCheckResult.fileName;
                this.setState({
                  checkResult: result,
                  isExportEnable: result.success,
                });
              } else {
                this.displayErrorNameScheme();
              }
            }
          } else {
            this.displayErrorNameScheme();
          }
        })
        .catch((err) => {
          console.log("Don't check uploading document.", err.message);
          this.displayErrorNameScheme();
        });
    }
  };

  displayErrorNameScheme() {
    const checkResult = {
      success: false,
      cleanFileName: this.nameRef.current?.value,
    };
    this.setState({ checkResult });
  }

  addFileExtension = (fileName) => {
    const { exportFormat } = this.state;
    const extension =
      exportFormat === exportType.DWG.value
        ? "dwg"
        : exportFormat === exportType.JPG.value
        ? "jpg"
        : "png";
    return `${this.changeUmlaut(fileName)}.${extension}`;
  };

  getTreeData(folders) {
    const treeData = folders.map((f) => {
      return this.getFolderTree(f);
    });

    let folder = this.state.folder;
    if (treeData.length) {
      if (!folder) {
        const data = treeData.find((f) => f.title === "Shared");
        if (data) folder = data.value;
      }
    }

    this.setState({ treeData, folder });
  }

  getFolderTree(folder) {
    let data = {};
    data.value = folder.id;
    data.title = folder.name;
    if (folder.childFolders) {
      data.children = folder.childFolders.map((sub) => {
        return this.getFolderTree(sub);
      });
    }

    return data;
  }

  setFilePath(isCreated) {
    this.setState({ isCreated });
  }

  completeExportPlan(result, documents) {
    if (result && !!documents) {
      const docs = JSON.parse(documents);
      const { exportHistories } = this.state;
      exportHistories.push({
        title: docs[0].name,
        version: docs[0].versionNumber,
        uploadDate: docs[0].timeStamp,
      });

      this.setState({ exportHistories });
    }
    const lang = window.isGerman ? de : en;
    const content = result ? lang["SucceedExport2D"] : lang["FaileCreatePlan"];
    window.AlertAPI(content);
    this.setState({ isCreated: false, isExporting: false });
  }

  renderTableData() {
    return this.state.exportHistories.map((doc, index) => {
      const { title, version, uploadDate } = doc;
      return (
        <tr className="striped_row" key={index}>
          <td width="50%">{title}</td>
          <td width="20%">{`V.${version}`}</td>
          <td width="30%">{Moment(uploadDate).format("DD.MM.YYYY")}</td>
        </tr>
      );
    });
  }

  getSchemeResult = (nameScheme) => {
    const lang = window.isGerman ? de : en;
    if (nameScheme.success) {
      const content = lang["NameValidSuccess"]
        .replace("{{versionNumber}}", nameScheme.predictedVersion)
        .replace("{{fileName}}", nameScheme.cleanFileName);
      return nameScheme.namingSchemeName
        ? `${lang[nameScheme.namingSchemeName]}: ${content}`
        : content;
    } else {
      if (nameScheme.errorType === "NamingSchemaValidationFailed") {
        return lang["NamingSchemaValidationFailed"]
          .replace("{{group}}", nameScheme.failedGroupName)
          .replace("{{namingSchema}}", nameScheme.namingSchemeName);
      }

      return nameScheme.namingSchemeName && nameScheme.errorType
        ? `${lang[nameScheme.namingSchemeName]}: ${lang[nameScheme.errorType]}}`
        : lang["NoNamingScheme"];
    }
  };

  renderDocName() {
    const nameScheme = this.state.checkResult;
    const firstName = nameScheme.success
      ? nameScheme.cleanFileName
      : nameScheme.cleanFileName.slice(0, nameScheme.errorPosition);
    const middleName = nameScheme.success
      ? ""
      : nameScheme.cleanFileName.slice(
          nameScheme.errorPosition,
          nameScheme.errorPosition + nameScheme.errorLength
        );
    const lastName = nameScheme.success
      ? ""
      : nameScheme.cleanFileName.slice(
          nameScheme.errorPosition + nameScheme.errorLength,
          nameScheme.cleanFileName.length
        );
    return (
      <>
        <span>{firstName}</span>
        <span style={{ color: "red" }}>
          {nameScheme.errorPosition !== undefined ? middleName : ""}
        </span>
        <span>{nameScheme.errorPosition !== undefined ? lastName : ""}</span>
      </>
    );
  }

  render() {
    const lang = window.isGerman ? de : en;
    const {
      isCreated,
      isHover,
      exportFormat,
      exportHistories,
      treeData,
      docNames,
      docName,
      isExportEnable,
      checkResult,
      isExporting,
    } = this.state;

    return (
      <>
        <div className="export-other">
          <h4>{lang["ExportOther"]}</h4>
          <div style={{ margin: "0 0 15px 0" }}>{lang["ExportOtherDesc"]}</div>
          <label>{lang["FileFormat"]}</label>
          <div className="export-content">
            <div>
              {exportFormat && (
                <VisoSelect
                  options={fileFormats}
                  onChange={this.handleChangeFileFormat}
                  defaultValue={exportFormat}
                />
              )}
            </div>
            <div>
              {!isCreated && (
                <button
                  className="visoplan_button"
                  style={{ minWidth: "180px" }}
                  onClick={this.handleCreate}
                >
                  {lang["Create2DPlan"]}
                </button>
              )}
              {isCreated && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "0 5px",
                  }}
                >
                  <img src={CheckImg} alt="" />
                  <div style={{ color: "#4B6FFC", padding: "0 10px" }}>
                    {lang["2DPlanCreated"]}
                  </div>
                  <div
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                  >
                    <FontAwesomeIcon
                      icon={isHover ? "times" : "times-circle"}
                      onClick={this.handleDelete}
                      style={{ color: "#666666" }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="export-content">
            <div>
              <label>Folder</label>
              {treeData && (
                <TreeSelect
                  suffixIcon={<CaretDownFilled />}
                  style={{ width: "100%" }}
                  value={this.state.folder}
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  onChange={this.onFolderChange}
                  treeData={treeData}
                />
              )}
            </div>
            <div>
              <label>Name</label>
              <CreateNameSelect
                defaultValue={docName}
                options={docNames}
                onChange={this.onNameChange}
                ref={this.nameRef}
              />
            </div>
          </div>
          <button
            className="visoplan_button"
            style={{ margin: "5px 0", width: "48%" }}
            onClick={this.onUploadCheck}
          >
            {lang["UploadCheck"]}
          </button>
          <div>
            <label style={{ fontWeight: "bold" }}>{lang["UploadCheck"]}</label>
            <div className="export-content">
              <label style={{ width: "35%", fontWeight: "normal" }}>
                {lang["Name"]}
              </label>
              <label style={{ width: "65%", fontWeight: "normal" }}>
                {"Information"}
              </label>
            </div>
            {checkResult && (
              <div
                className="export-content"
                style={{ width: "calc(100vw - 40px)" }}
              >
                <label
                  style={{
                    fontWeight: "normal",
                    width: "35%",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {this.renderDocName()}
                </label>
                <label
                  style={{
                    fontWeight: "normal",
                    width: "65%",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    color: `${checkResult.success ? "none" : "red"}`,
                  }}
                >
                  {this.getSchemeResult(checkResult)}
                </label>
              </div>
            )}
          </div>
          {isExportEnable && isCreated && (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button
                className="visoplan_Actbutton"
                style={{ width: "180px" }}
                onClick={this.handleExport}
              >
                Export
              </button>
            </div>
          )}
          <div className="exportOther-table scrollbar">
            <label style={{ margin: "5px", fontWeight: "bold" }}>
              {lang["ExportHistory"]}
            </label>
            <table>
              <thead>
                <tr className="no_border">
                  <th>{lang["Name"]}</th>
                  <th>{"Version"}</th>
                  <th>{"Date"}</th>
                </tr>
              </thead>
              <tbody>{exportHistories && this.renderTableData()}</tbody>
            </table>
          </div>
        </div>
        <Backdrop
          style={{
            zIndex: 100,
            display: "flex",
            alignItems: "center",
            alignContent: "center",
          }}
          open={isExporting}
        >
          <CircularProgress />
        </Backdrop>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    project: state.project.selectedProject,
    user: state.user.currentUser,
  };
};

export default connect(mapStateToProps, null)(ExportOther);
