export const roleAction = {
  ActivityLog_Access: 0,
  ActivityLog_Export: 1,
  Project_ModelUpload: 100,
  Project_Copy: 101,
  Project_EditMembers: 103,
  IssueManagement_Creation: 200,
  IssueManagement_Editing: 201,
  IssueManagement_StatusEditing: 202,
  IssueManagement_CreatingComments: 203,
  IssueManagement_BfcUpload: 204,
  IssueManagement_Export: 205,
  IssueManagement_Assigned_Editing: 206,
  IssueManagement_Assigned_StatusEditing: 207,
  IssueManagement_Creator_Editing: 208,
  IssueManagement_Creator_StatusEditing: 209,
  IssueManagement_Reviewer_Editing: 210,
  IssueManagement_Reviewer_StatusEditing: 211,
  IssueManagement_StatusEditing_SetClosed: 212,
  IssueManagement_StatusEditing_UnsetClosed: 213,
  Document_Upload: 300,
  Document_Download: 301,
  Document_Editing: 302,
  Document_Sharing: 303,
  Document_Delete: 304,
  Document_CommentEditing: 305,
  Document_Archiving: 306,
  ment_GetArchived: 307,
  Document_RestoreArchived: 308,
  Document_IfcImport: 309,
  Folder_Edit: 400,
  Folder_EditAccessList: 401,
  Folder_Create: 402,
  Folder_Delete: 403,
  Tag_Create_Update: 500,
  Tag_Delete: 501,
  MainMenu_Dashboard: 600,
  MainMenu_Models: 601,
  MainMenu_Issues: 602,
  MainMenu_History: 603,
  MainMenu_EditProperties: 604,
  MainMenu_Documents: 605,
  MainMenu_Folders: 606,
  MainMenu_LiveView: 607,
  MainMenu_Collaborators: 608,
  MainMenu_Settings: 609,
  MainMenu_IssueTimeLine: 610,
  Email_View: 700,
  NamingScheme_Create_Edit: 800,
  NamingScheme_Delete: 801,
  DocumentList_Delete: 900,
  Process_Create: 1000,
  Process_ViewAll: 1001,
  DocStatus_Create_Edit: 1100,
  DocStatus_Delete: 1101,
  DocStatus_ChangeToPublished: 1103,
  DocStatus_ChangeFromPublished: 1104,
  DocStamp_Edit: 1150,
  IssueStatus_Create_Edit: 1200,
  IssueStatus_Delete: 1201,
  IssueType_CreateEdit: 1202,
  IssueType_Delete: 1203,
  IssuePriority_CreateEdit: 1204,
  IssuePriority_Delete: 1205,
  SmartView_DeleteFromDifferentCreator: 1300,
  Inbox_Edit: 1400,
  Inbox_AllowUploadEmail: 1401,
  BimqIntegration: 1500,
  NovaAvaIntegration: 1600,
};
