import { processConstants } from "../constants";

const initialState = {
    processes: []
}

export function processes(state = initialState, action) {
    switch (action.type) {
        case processConstants.PROCESS_GETALL: {
            return {
                ...state,
                processes: action.payload
            };
        }
        case processConstants.PROCESS_CLEAR:
            return initialState;
        default:
            return state;
    }
}