import React from "react";
import { connect } from "react-redux";
import { apiService } from "../../services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import en from "../../assets/en.json";
import de from "../../assets/de.json";
import "../../css/processItem.css";

const PropertyValueType = {
  Text: 0,
  Boolean: 1,
  Logical: 2,
  Integer: 3,
  Real: 4,
};

class ProcessItem extends React.Component {
  importProcess = async () => {
    if (this.checkAccessRight()) {
      const data = await this.getProcessData();

      if (data && data.length) {
        const model = {};
        model.id = this.props.process.id;
        model.user = `${this.props.user.firstName} ${this.props.user.lastName}`;
        model.data = data;
        window.importProcess(model);
      }
    } else {
      const lang = window.isGerman ? de : en;
      window.AlertAPI(lang["AccessProcessFail"]);
    }
  };

  checkAccessRight() {
    const userId = this.props.user.id;
    const process = this.props.process.process;
    const collIds = process.collaborators.map((u) => {
      return u.id;
    });
    return process.creatorId === userId || collIds.includes(userId);
  }

  async getProcessData() {
    const processId = this.props.process.process.id;
    const assign = await apiService.getPropertyAssignment(processId);
    const def = await apiService.getPropertyDefinition(processId);

    const definitions = def.data.propertyDefinitions;
    return assign.data.propertyAssignments.map((assignment) => {
      const data = {};
      data.globalId = assignment.componentGlobalId;
      data.properties = [];
      const property = definitions.find(
        (d) => d.id === assignment.propertyDefinitionId
      );
      if (property) {
        let value = this.getPropertValue(property, assignment);

        const item = {
          value: value,
          propertyName: property.propertyName,
          propertySetName: property.propertySetName,
        };
        data.properties.push(item);
      }

      return data;
    });
  }

  getPropertValue(property, assignment) {
    let value = "";
    switch (property.valueType) {
      case PropertyValueType.Text:
        value = assignment.textValue;
        break;
      case PropertyValueType.Boolean:
        value = assignment.booleanValue ? "1" : "0";
        break;
      case PropertyValueType.Integer:
        if (assignment.intValue !== undefined && assignment.intValue !== null) {
          value = assignment.intValue.toString();
        }
        break;
      case PropertyValueType.Real:
        if (
          assignment.realValue !== undefined &&
          assignment.realValue !== null
        ) {
          value = assignment.realValue.toString();
        }
        break;
      default:
        value = assignment.textValues[0];
        break;
    }

    return value;
  }

  render() {
    const { process } = this.props;
    const lang = window.isGerman ? de : en;

    return (
      <div className="processItem">
        <div className="processItem_title">
          <label style={{ fontWeight: "bold", fontSize: "16px" }}>
            {process.process.name}
          </label>
          <button
            className="visoplan_Actbutton"
            style={{ width: "110px" }}
            onClick={this.importProcess}
          >
            {process.imported ? lang["Reimport"] : lang["Import"]}
          </button>
        </div>
        <div className="processItem_content">
          <div className="processItem_subContent">
            <label style={{ fontWeight: "bold" }}>{lang["Model"]}</label>
            <div style={{ fontWeight: "bold" }}>{process.model}</div>
          </div>
          <div className="processItem_subContent">
            <label>{lang["Discipline"]}</label>
            <div>{process.discipline}</div>
          </div>
          <div className="processItem_subContent">
            <label>{lang["Building"]}</label>
            <div>{process.building}</div>
          </div>
          <div className="processItem_subContent">
            <label>{lang["Floor"]}</label>
            <div>{process.floor}</div>
          </div>
        </div>
        <div className="processItem_bottom">
          <div>
            <FontAwesomeIcon
              icon={faClock}
              color={"#666666"}
              style={{ margin: "0 3px 0 0" }}
            />
            {<div>{process.description}</div>}
          </div>
          {process.imported && (
            <div>
              <FontAwesomeIcon
                icon={faCheckCircle}
                color={"#4B6FFC"}
                style={{ margin: "0 5px 0 0" }}
              />
              <div style={{ color: "#4B6FFC" }}>{lang["Imported"]}</div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.currentUser,
  };
};

export default connect(mapStateToProps, null)(ProcessItem);
