import { propertyAssignmentStatus } from "../../components/enums";
import { apiService } from "../../services"
import { processConstants } from "../constants";

export const processActions = {
    getProcesses
}

function getProcesses(ids) {
    return dispatch => {
        apiService.getArrayData('processnew', ids)
            .then(data => {
                const approved = data.filter(d => d.status === propertyAssignmentStatus.Approved);
                dispatch(registerProcesses(approved));
            }).catch(err => {
                console.log('Process getting failed.', err);
            });
    };

    function registerProcesses(data) {
        return {
            type: processConstants.PROCESS_GETALL,
            payload: data
        };
    }
}