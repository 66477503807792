import React from 'react';
import { connect } from 'react-redux';
import VisoSelect from './viso_select';
import Moment from 'moment';
import { converters } from '../helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import en from '../../assets/en.json';
import de from '../../assets/de.json';
import '../../css/importItem.css';

library.add(faTimes, faTimesCircle);

class ImportItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disciplines: [], buildings: [], floors: [], isUpdated: false,
            disciplineId: '', buildingId: '', floorId: '', isHover: false, isAddable: false,
            versionModels: [], selectedModelId: '', selectedFileId: '', fileName: '', isDisciplineAlone: false, isBuildingAlone: false, isFloorAlone: false,
        };
    }

    componentDidMount() {
        this.initialize();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.disciplines !== prevProps.disciplines) {
            const disciplines = converters.getOptionsFromMetaData(this.props.disciplines);
            if (disciplines.length === 1) {
                const disciplineId = disciplines[0].value;
                this.setState({ isDisciplineAlone: true, disciplineId: disciplineId, disciplines: disciplines });
            } else {
                this.setState({ disciplines: disciplines, isDisciplineAlone: false, disciplineId: '' });
            }
        }

        if (this.props.buildings !== prevProps.buildings) {
            const buildings = converters.getOptionsFromMetaData(this.props.buildings);
            if (buildings.length === 1) {
                const buildingId = buildings[0].value;
                this.setState({ isBuildingAlone: true, buildingId: buildingId, buildings: buildings });
            } else {
                this.setState({ isBuildingAlone: false, buildingId: '', buildings: buildings });
            }
        }

        if (this.props.floors !== prevProps.floors) {
            const floors = converters.getOptionsFromMetaData(this.props.floors);
            if (floors.length === 1) {
                const floorId = floors[0].value;
                this.setState({ isFloorAlone: true, floorId: floorId, floors: floors });
            } else {
                this.setState({ isFloorAlone: false, floorId: '', floors: floors });
            }
        }

        if (this.props.model !== prevProps.model) {
            this.initialize();
        }

        if (this.state.disciplineId !== prevState.disciplineId) {
            this.props.model.disciplineMetaDataId = this.state.disciplineId;
            this.checkState();
        }

        if (this.state.buildingId !== prevState.buildingId) {
            this.props.model.buildingMetaDataId = this.state.buildingId;
            this.checkState();
        }

        if (this.state.floorId !== prevState.floorId) {
            this.props.model.floorMetaDataId = this.state.floorId;
            this.checkState();
        }

        if (prevState.selectedFileId !== this.state.selectedFileId) {
            this.onChangeFileId();
        }

        if (this.props.models !== prevProps.models) {
            this.getModelVersions();
        }
    }

    initialize() {
        const disciplines = converters.getOptionsFromMetaData(this.props.disciplines);
        const buildings = converters.getOptionsFromMetaData(this.props.buildings);
        const floors = converters.getOptionsFromMetaData(this.props.floors);

        const model = this.props.model;

        if (model.fileId) {
            this.setState({
                disciplines: disciplines, buildings: buildings, floors: floors, disciplineId: model.disciplineMetaDataId, isDisciplineAlone: true, isBuildingAlone: true, isFloorAlone: true
                , buildingId: model.buildingMetaDataId, floorId: model.floorMetaDataId, selectedFileId: model.fileId, fileName: model.name
            });
        } else {
            let disciplineId = '';
            let buildingId = '';
            let floorId = '';
            let isDisciplineAlone = false;
            let isBuildingAlone = false;
            let isFloorAlone = false;
            if (disciplines.length === 1) {
                isDisciplineAlone = true;
                disciplineId = disciplines[0].value;
            }

            if (buildings.length === 1) {
                isBuildingAlone = true;
                buildingId = buildings[0].value;
            }

            if (floors.length === 1) {
                isFloorAlone = true;
                floorId = floors[0].value;
            }
            this.setState({
                disciplines: disciplines, buildings: buildings, floors: floors, disciplineId: disciplineId
                , buildingId: buildingId, floorId: floorId, selectedFileId: '', isDisciplineAlone: isDisciplineAlone, isBuildingAlone: isBuildingAlone, isFloorAlone: isFloorAlone
            });
        }
    }

    handleChangeDiscipline = (discipline) => {
        this.setState({ disciplineId: discipline });
    }

    handleChangeBuilding = (building) => {
        this.setState({ buildingId: building });
    }

    handleChangeFloor = (floor) => {
        this.setState({ floorId: floor });
    }

    handleChangeVersion = (fileId) => {
        this.setState({ selectedFileId: fileId });
    }

    onChangeFileId() {
        const model = this.props.models.find(m => m.fileId === this.state.selectedFileId);
        const fileName = model !== undefined ? model.name : '';
        this.setState({ fileName: fileName });
        if (model) {
            const extension = this.getFileExtension(model.fileName);
            if (extension.includes('xml')) {
                const lang = window.isGerman ? de : en;
                window.alertAPI(lang['DontOpenIfcXml']);
            }

            this.props.onChangeFileId(this.state.selectedFileId, model.fileName, this.props.index - 1);
        }
    }

    getFileExtension(fileName) {
        return fileName.split('.').pop().toLowerCase();
    }

    handleMouseEnter = () => {
        this.setState({ isHover: true });
    }

    handleMouseLeave = () => {
        this.setState({ isHover: false });
    }

    handleDelete = () => {
        this.props.onDelete(this.props.index - 1);
    }

    checkState() {
        const addable = this.isStringFull(this.state.disciplineId)
            && this.isStringFull(this.state.buildingId)
            && this.isStringFull(this.state.floorId);

        this.setState({ isAddable: addable });
        if (addable) {
            this.getModelVersions();
        }
    }

    isStringFull(content) {
        return content !== '';
    }

    getDisciplineName() {
        const discipline = this.state.disciplines.find(d => d.value === this.state.disciplineId);
        if (discipline)
            return discipline.label;

        return '';
    }

    getBuildingName() {
        const building = this.state.buildings.find(b => b.value === this.state.buildingId);
        if (building)
            return building.label;

        return '';
    }

    getFloorName() {
        const floor = this.state.floors.find(f => f.value === this.state.floorId);
        if (floor)
            return floor.label;

        return '';
    }

    getModelVersions() {
        if (this.props.models) {
            const models = this.props.models.filter(m => {
                return m.disciplineMetaDataId === this.state.disciplineId
                    && m.buildingMetaDataId === this.state.buildingId
                    && m.floorMetaDataId === this.state.floorId;
            }).sort(function (a, b) { return Date.parse(b.creationDate) - Date.parse(a.creationDate) })
                .map(m => { return { value: m.fileId, label: Moment(m.creationDate).format('DD.MM.YYYY') } });

            if (models.length) {
                const fileId = this.state.selectedFileId ? this.state.selectedFileId : models[0].value;
                this.setState({ versionModels: models, selectedFileId: fileId, isAddable: true });
            } else {
                this.setState({ isAddable: false, selectedFileId: '' });
            }
        }
    }

    render() {
        const lang = window.isGerman ? de : en;
        const {
            isUpdated, disciplines, buildings, floors, isHover, isAddable, versionModels, selectedFileId, disciplineId, buildingId, floorId, fileName
            , isDisciplineAlone, isBuildingAlone, isFloorAlone
        } = this.state;

        const isAdded = this.props.model.fileId !== undefined && this.props.model.fileId !== '';

        return (
            <div className='importItem'>
                <div className='importItem_header'>
                    <div>
                        <label>{fileName}</label>
                        {isUpdated && <label style={{ color: 'red', margin: '0 10px' }}>Update</label>}
                    </div>
                </div>
                <div className="importItem_body">
                    <div>
                        <div className='part_1' style={{ backgroundColor: '#F4F4F4' }}>
                            <label>{lang['Discipline']}</label>
                            <div>
                                {!isDisciplineAlone && !isAdded && <VisoSelect options={disciplines} onChange={this.handleChangeDiscipline} defaultValue={disciplineId} />}
                                {!isDisciplineAlone && isAdded && <label>{this.getDisciplineName()}</label>}
                                {isDisciplineAlone && <label>{this.getDisciplineName()}</label>}
                            </div>
                            <label>{lang['Building']}</label>
                            <div>
                                {!isBuildingAlone && !isAdded && <VisoSelect options={buildings} onChange={this.handleChangeBuilding} defaultValue={buildingId} />}
                                {!isBuildingAlone && isAdded && <label>{this.getBuildingName()}</label>}
                                {isBuildingAlone && <label>{this.getBuildingName()}</label>}
                            </div>
                        </div>
                        <div className='part_1' style={{ backgroundColor: 'white' }}>
                            <label>{lang['Floor']}</label>
                            {!isFloorAlone && !isAdded && <VisoSelect options={floors} onChange={this.handleChangeFloor} defaultValue={floorId} />}
                            {!isFloorAlone && isAdded && <label>{this.getFloorName()}</label>}
                            {isFloorAlone && <label>{this.getFloorName()}</label>}
                            <label>Version</label>
                            {isAddable && <VisoSelect options={versionModels} onChange={this.handleChangeVersion} defaultValue={selectedFileId} />}
                        </div>
                    </div>
                    <div>
                        <div onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
                            <FontAwesomeIcon icon={isHover ? 'times-circle' : 'times'} onClick={this.handleDelete} style={{ color: '#666666' }} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        disciplines: state.metaData.disciplines,
        buildings: state.metaData.buildings,
        floors: state.metaData.floors,
        models: state.models.models
    }
}

export default connect(mapStateToProps, null)(ImportItem);